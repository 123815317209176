import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'


const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>活用促進事業</title>
            <meta name="description" content="都市構造可視化の活用のための研修の実施および支援の事業についての概要" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>活用促進事業のご案内</h1>
                    </header>
                    <span className="image main"><img src="/mac.jpg" alt="" /></span>
                        <h3>
                            都市構造可視化の活用促進およびノウハウの共有のため、出先等どこでも可視化を行えるよう必要な機材をお渡しし、活用事例をご報告いただく事業を行っています。
                        </h3>
                        <h4>
                            １）参加条件
                        </h4>
                        <p>
                        　都市計画ビジュアルコミュニケーター検定合格者または、過去に可視化に関する研修会等を受講したことのある方<br/>
                        　（なお、活用事例の収集に資するため、研修同様にパワーポイント８枚での活用事例のご報告をお願いしております）</p>
                        <h4>
                            ２）活用促進事業に参加される方にお渡しする機器のスペック等
                        </h4>
                        <p>
                            ・MacBook Air 13インチ及び付属品または相当品<br/>
                            ・SpaceMouse または相当品<br/>
                            ・Pocket WiFi SoftBank 802ZTまたは相当品（通信契約含む）
                        </p>
                        <h4>
                            ３）期間中のサポート
                        </h4>
                        <p>
                        　上記機材をご活用いただいている期間中、使い方のサポート、独自データのkml変換サービスの活用について、随時リモート会議でご相談いただくことが可能です。</p>
                        <br/>
                        <hr/>
                        <h4>
                            なお、上記によらず、研修支援業務では研修期間中、人数分の機材をお使いいただけます。
                        </h4>
                        <p>初めての方、複数台が必要な場合などは、こちらがおすすめです。</p>
                        <ul className="actions">
                                <li><Link to="/kensyu" className="button">研修支援業務のご案内</Link></li>
                            </ul>

                        <hr/>
                        <h4>
                            なお、上記によらず、自治体会員として入会いただくと、１セットを無料でお使いいただけます。
                        </h4>
                        <p>会員特典としての扱いをご希望の方は、こちらがおすすめです。</p>
                        <ul className="actions">
                                <li><Link to="/nyukaidetail" className="button">入会のご案内</Link></li>
                            </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic